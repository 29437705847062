import { StudentService } from './../student.service';
/**
 * Created by LifeSoft on 15/10/18.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
@Injectable()
export class StudentWithClassResolver implements Resolve<any> {

  constructor(private studentService: StudentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.params['id'];

    if (id) {
     return this.studentService.getStudentById(id);
    }
    return null;
  }

}
