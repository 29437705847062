import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '../../shared/shared.module';
import { EmployeesLayoutComponent } from './employees-layout.component';
import { EmployeesLayoutRoutingModule } from './employees-layout-routing.module';
import { AdminTLMComponent } from './teacher-learner-material/admin-tlm.component';
import { EmployeeSettingComponent } from './dashboard/employee-setting/employee-setting.component';
import { EmployeeProfileComponent } from './profile/employee-profile.component';
import { EmployeePayrollComponent } from './payroll/view/employee-payroll.component';
import { EmployeePayrollItemsComponent } from './payroll/items/employee-payroll-items.component';
import { StudentAttendanceComponent } from './student-attendance/student-attendance.component';
import { MarkStudentAttendanceComponent } from './student-attendance/mark-student-attendance/mark-student-attendance.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		EmployeesLayoutRoutingModule,
		NgbModule.forRoot(),
		SharedModule,
		HttpClientModule
	],
	declarations: [
		EmployeesLayoutComponent, DashboardComponent, AdminTLMComponent, EmployeeSettingComponent, EmployeeProfileComponent, EmployeePayrollComponent, EmployeePayrollItemsComponent, StudentAttendanceComponent, MarkStudentAttendanceComponent
	],
})
export class EmployeesLayoutModule { }

