/**
 * Created by LifeSoft on 19/02/18.
 */
import {Component, OnInit} from '@angular/core';
import {ErrorService} from '../../../shared/services/util/error.service';
import {NotificationsService} from 'angular2-notifications';
import {Title} from '@angular/platform-browser';
import {TLMData, TLMDataResponse} from '../../../models/tlm-data';
import {Subscription} from 'rxjs';
import {DefaultApiService} from '../../../shared/services/default-api.service';
import {MicService} from '../../../models/util/mic.service';
import {MainResponse} from '../../../models/response/main-response';
import {DefaultHeaderService} from '../../../shared/services/util/default-header.service';
import {HttpHeaders} from '@angular/common/http';
@Component({
  selector: 'app-admin-tlm',
  templateUrl: './admin-tlm.component.html'
})
export class AdminTLMComponent implements OnInit {
  title = 'Approve Teacher Learner Materials';
  tlms: TLMData[];
  loading  = false;
  approveBusy = false;
  data: any;
  rowsOnPage = 10;
  filterQuery = '';
  sortBy = '';
  sortOrder = 'desc';
  selectedTLM: TLMData;
  studentView: boolean;
  approve: string;
  tlmTopic = '';
  constructor(private errorService: ErrorService,
              private notificationService: NotificationsService,
              private _title: Title,
              private defaultApi: DefaultApiService,
              private micService: MicService) { }
  ngOnInit(): void {
    this._title.setTitle(this.title);
    // console.log(this.defaultHeaders.getDefaultAuthHeaders());
    this.onPageStart();
  }
  onPageStart(): void {
    this.getAllTLMForApproval();
  }
  onRefresh(refresh: boolean): void {
    if (refresh) {
      this.onPageStart();
    }
  }
  getAllTLMForApproval(): void {
    const url = 'api/v1/teacher-learner-materials/get-all-tlms';
    this.loading = true;
    this.defaultApi.getAll(url)
                .subscribe((response: TLMDataResponse) => {
                  this.loading = false;
                  if (response.status) {
                    this.tlms = response.data.tlms;
                    this.buildTLMDataTable(this.tlms);
                  } else {
                    this.notificationService.warn('Teacher Learner Materials', response.message);
                  }
                }, (error) => {
                  this.loading = false;
                  this.errorService.handleError(error);
                });
  }

  buildTLMDataTable(tlms: TLMData[]): void {
    this.data = [];
    for (const tlm of tlms) {
      this.data.push({
        name: tlm.tlmTopic.topic,
        subject: tlm.tlmTopic.subject.code + ' ' + tlm.tlmTopic.subject.name,
        type: tlm.type,
        employee: this.micService.getFullNameDefault(tlm.tlmTopic.employee),
        weeks: tlm.tlmTopic.weeks,
        classes: tlm.tlmTopic.classes,
        tlm: tlm
      });
    }
  }
  onChangeStudentView(input: boolean): void {
    this.studentView = input;
  }
  onPreviewTLM(tlm: TLMData, modal: any): void {
    this.selectedTLM = tlm;
    this.tlmTopic = tlm.tlmTopic.topic;
    this.approve  = (tlm.status !== 'APPROVE') ? 'APPROVE' : 'REJECT';
    this.studentView = tlm.canView;
    modal.show();
  }
  downloadFile(fileName: string, id: number): void {
    const url = 'api/v1/teacher-learner-materials/download-file/' + id;
    this.loading = true;
    this.defaultApi.getFile(url).subscribe((response) => {
      this.loading = false;
      if (response.type !== 'application/json') {
        const a = document.createElement('a'),
          fileURL = URL.createObjectURL(response);
        // a.style = "display:none";
        a.href = fileURL;
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      } else {
        this.notificationService.warn('TLM Data', 'Failed to Download TLM');
      }
    }, (error) => {
      this.loading = false;
      this.errorService.handleError(error);
    });
  }
  onSubmit(modal: any): void {
    const url = 'api/v1/teacher-learner-materials/approve/' + this.selectedTLM.id;
    this.approveBusy = true;
    this.defaultApi.save({canView: this.studentView, status: this.approve }, url)
                    .subscribe((response: MainResponse) => {
                        this.approveBusy = false;
                        if (response.status) {
                          this.getAllTLMForApproval();
                          this.notificationService.success('TLM Data', response.message);
                          modal.hide();
                        } else {
                          this.notificationService.warn('TLM Data', response.message);
                        }
                    }, (error) => {
                      this.approveBusy = false;
                      this.errorService.handleError(error);
                    });
  }
}
