import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error4Component } from './error/error4/error4.component';
import { CommonModule } from '@angular/common';
import { CanDeactivateGuard } from './shared/guard/util/can-deactivate-guard.service';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: '404', component: Error4Component},
  {path: '**', redirectTo: '404'}
];
@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class AppRoutingModule { }
