import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';

@NgModule({
  imports: [
    CommonModule,
    NgSlimScrollModule
  ],
  exports: [NgSlimScrollModule],
  providers: [
    // OPTIONAL : provide default global settings which will be merge with component options.
    {
      provide: SLIMSCROLL_DEFAULTS,
      useValue: {
        alwaysVisible : false,
        barBackground: '#0073aa',
      }
    },
  ]
})
export class ScrollModule { }
