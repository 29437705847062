import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-nrenza-validation',
  templateUrl: './nrenza-validation.component.html',
  styleUrls: ['./nrenza-validation.component.css']
})
export class NrenzaValidationComponent implements OnInit {
  @Input() errorMsg: string;
  @Input() displayError: boolean;
  constructor() { }

  ngOnInit() {
  }

}
