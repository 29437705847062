/**
 * Created by LifeSoft on 08/01/18.
 */
import {Injectable} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';
@Injectable()
export class ErrorService {
  constructor(private notificationService: NotificationsService) { }
  handleError(error: any) {
    if (error instanceof Error) {
      this.notificationService.error('Error', 'Please Check Your Internet!');
    } else {
      if (error.status >= 400 && error.status <= 500) {
        this.notificationService.error('Error', error.statusText);
      } else if (error.status >= 500) {
        if (error.status === 503) {
          // maintenance mode
          let message = error.error.message;

          if (message === 'Locked') {
            this.notificationService.error('Error', 'School Account is Temporary Suspended');
          } else {
            message = message === '' ? error.statusText : message;
            this.notificationService.error('Error', message);
          }

        } else {
          this.notificationService.error('Error', error.statusText);
        }
      } else {
        // :)
        this.notificationService.error('Error', 'Try Reloading Page');
      }
    }
  }
}
