import {Injectable} from '@angular/core';
import {LoginUserBody} from '../../models/login-user-body';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {User} from '../../models/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class AuthService {
    redirectUrl: string;
    constructor(private _http: HttpClient) { }
    attemptLogin(loginUser: LoginUserBody): Observable<any> {
        const headers = new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        });
        return this._http.post(environment.base_url + 'api/v1/users/token-login',
                JSON.stringify(loginUser), {headers: headers});
    }
    saveLoginUser(user: User): void {
        if (user != null) {
            localStorage.setItem('login', JSON.stringify(true));
            localStorage.setItem('user', JSON.stringify(user));
        }
    }
    getLoginUser(): User | null {
       return JSON.parse(localStorage.getItem('user'));
    }
    hasSameUserType($type: string): boolean {
        const user = this.getLoginUser();
        if (user != null) {
            if (($type === 'employee') && (user.level === 'admin')) {
                return true;
            }
            return ($type === user.level);
        }
        return false;
    }

    isLogin(): boolean {
        const login = JSON.parse(localStorage.getItem('login'));
        return (login != null && login);
    }
    logout(): void {
      localStorage.clear();
    }
}
