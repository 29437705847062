import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EmployeeGaurd} from '../../shared/guard/employee-guard.service';
import {EmployeesLayoutComponent} from './employees-layout.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AdminTLMComponent} from './teacher-learner-material/admin-tlm.component';
import {EmployeeSettingComponent} from './dashboard/employee-setting/employee-setting.component';
import { EmployeeProfileComponent } from './profile/employee-profile.component';
import { EmployeePayrollComponent } from './payroll/view/employee-payroll.component';
import { EmployeePayrollItemsComponent } from './payroll/items/employee-payroll-items.component';
import { StudentAttendanceComponent } from './student-attendance/student-attendance.component';
import { MarkStudentAttendanceComponent } from './student-attendance/mark-student-attendance/mark-student-attendance.component';

const routes: Routes = [
    {
        path: 'employees', component: EmployeesLayoutComponent, canActivate: [EmployeeGaurd], pathMatch: 'prefix',
        children: [
            {path: '', canActivateChild: [EmployeeGaurd],
              children: [
                {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
                {path: 'dashboard', component: DashboardComponent},
                {path: 'change-password', component: EmployeeSettingComponent},
                {path: 'human-resources',
                  loadChildren: 'src/app/employee-layout/employees/human-resource/human-resource.module#HumanResourceModule' },
                {path: 'general-settings',
                  loadChildren: 'src/app/employee-layout/employees/general-settings/general-settings.module#GeneralSettingsModule' },
                {path: 'students',
                  loadChildren: 'src/app/employee-layout/employees/students/student-admission.module#StudentAdmissionModule' },
                {path: 'subjects',
                  loadChildren: 'src/app/employee-layout/employees/subjects/subject.module#SubjectModule' },
                {path: 'examinations',
                  loadChildren: 'src/app/employee-layout/employees/examination/examination.module#ExaminationModule' },
                {path: 'users',
                  loadChildren: 'src/app/employee-layout/employees/users/users.module#UsersModule' },
                {path: 'attendance',
                  loadChildren: 'src/app/employee-layout/employees/attendance/attendance.module#AttendanceModule' },
                {path: 'messaging-center/sms',
                  loadChildren: 'src/app/employee-layout/employees/sms/sms.module#SmsModule' },
                {path: 'messaging-center/notifications', loadChildren: './messaging-center/messaging-center.module#MessagingCenterModule' },
                {path: 'finances',
                  loadChildren: 'src/app/employee-layout/employees/finances/finances.module#FinancesModule' },
                {path: 'canteens',
                  loadChildren: 'src/app/employee-layout/employees/canteen/canteen.module#CanteenModule' },
                {path: 'stores',
                  loadChildren: 'src/app/employee-layout/employees/store/store.module#StoreModule' },
                {path: 'extra-bills',
                  loadChildren: 'src/app/employee-layout/employees/extra-bill/extra-bill.module#ExtraBillModule' },
                {path: 'independent-bills',
                  loadChildren: 'src/app/employee-layout/employees/independent-bill/independent-bill.module#IndependentBillModule' },
									{path: 'teacher-learner-materials/approve', component: AdminTLMComponent },
									{path: 'profile', component: EmployeeProfileComponent },
									{path: 'human-resources/payroll/payslip-items', component: EmployeePayrollItemsComponent },
									{path: 'human-resources/payroll/view', component: EmployeePayrollComponent },
									{path: 'students/student-attendance', component: StudentAttendanceComponent },
                {path: 'students/mark-student-attendance', component: MarkStudentAttendanceComponent },
              ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EmployeesLayoutRoutingModule { }
