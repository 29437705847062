/**
 * Created by LifeSoft on 02/11/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {DefaultHeaderService} from './util/default-header.service';
import {AddPositionBody} from '../../models/util/add-position-body';
@Injectable()
export class EmployeePositionService {
    base_url = environment.base_url;
    constructor(private http: HttpClient, private header: DefaultHeaderService) { }
    getPositions(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/positions', {headers: this.header.getDefaultAuthHeaders()});
    }
    addPosition(position: AddPositionBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/positions/store', JSON.stringify(position),
            {headers: this.header.getDefaultAuthHeaders()});
    }
    updatePosition(id: number, position: AddPositionBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/positions/update/' + id, JSON.stringify(position),
            {headers: this.header.getDefaultAuthHeaders()});
    }
    getActivePositions(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/positions?active=1', {headers: this.header.getDefaultAuthHeaders()});
    }
}
