/**
 * Created by LifeSoft on 31/10/18.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {DefaultApiService} from '../default-api.service';
@Injectable()
export class AcademicCalendarResolver implements Resolve<any> {
  constructor(private defaultApi: DefaultApiService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>  {
    return this.defaultApi.getAll('api/v1/academic-years');
  }

}
