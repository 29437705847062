import { EmployeeService } from './../employee.service';
/**
 * Created by LifeSoft on 16/10/18.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {DefaultApiService} from '../default-api.service';
@Injectable()
export class LeanEmployeeWithResolver implements Resolve<any> {

  constructor(private employeeService: EmployeeService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.params['id'];
    if (id){
      return this.employeeService.getEmployeeById(id);
    }
    return null;
  }

}
