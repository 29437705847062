/**
 * Created by LifeSoft on 13/11/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {DefaultHeaderService} from './util/default-header.service';
import {EduGradeBody} from '../../models/edu-grade';
import {EduScoreBody} from '../../models/edu-score';
@Injectable()
export class GradingSystemService {
    base_url = environment.base_url;
    constructor(private http: HttpClient, private header: DefaultHeaderService) { }
    getActiveGrades(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/grading-systems?active=1', {headers: this.header.getDefaultAuthHeaders()});
    }
    getGrades(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/grading-systems', {headers: this.header.getDefaultAuthHeaders()});
    }
    saveGradingSystem(body: EduGradeBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/grading-systems/store', JSON.stringify(body), {
            headers: this.header.getDefaultAuthHeaders()
        });
    }
    updateGradingSystem(id: number, body: EduGradeBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/grading-systems/update/' + id, JSON.stringify(body), {
            headers: this.header.getDefaultAuthHeaders()
        });
    }
    getGradingActiveScores(grade_id: number): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/grading-system-scores?active=1&edu_grade_id=' + grade_id,
            {headers: this.header.getDefaultAuthHeaders()});
    }
    getGradingScores(grade_id: number): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/grading-system-scores?edu_grade_id=' + grade_id,
            {headers: this.header.getDefaultAuthHeaders()});
    }
    saveGradingSystemScore(grade_id: number, body: EduScoreBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/grading-system-scores/store/' + grade_id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()
        });
    }
    updateGradingSystemScore(score_id: number, body: EduScoreBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/grading-system-scores/update/' + score_id, JSON.stringify(body), {
            headers: this.header.getDefaultAuthHeaders()
        });
    }
}
