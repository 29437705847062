/**
 * Created by LifeSoft on 09/01/18.
 */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StudentsLayoutComponent} from './students-layout.component';
import {StudentGuardService} from '../../shared/guard/student-guard.service';
import {StudentDashboardComponent} from './dashboard/student-dashboard.component';
import {StudentProfileComponent} from './profile/student-profile.component';
import {StudentSettingsComponent} from './settings/student-settings.component';
import {StudentTLMDataComponent} from './tlm-data/tlm-data.component';
const routes: Routes = [
  {
    path: 'students',
    component: StudentsLayoutComponent,
    canActivate: [StudentGuardService],
    pathMatch: 'prefix',
    children: [
      {path: '', canActivateChild: [StudentGuardService],
        children: [
          {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
          {path: 'dashboard', component: StudentDashboardComponent},
          {path: 'profile', component: StudentProfileComponent},
          {path: 'account-settings', component: StudentSettingsComponent},
          {path: 'course-materials/all', component: StudentTLMDataComponent},
          {path: 'results-checker',
          loadChildren: 'src/app/student-layout/students/results/student-result-checker.module#StudentResultCheckerModule' },
          {path: 'online-examinations',
            loadChildren:
            'src/app/student-layout/students/student-online-examination/student-online-examination.module#StudentOnlineExaminationModule' }
        ]
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentLayoutRoutingModule { }
