import {NgModule} from '@angular/core';
import {WithSocialComponent} from './with-social/with-social.component';
import {WithHeaderFooterComponent} from './with-header-footer/with-header-footer.component';
import {WithBgImageComponent} from './with-bg-image/with-bg-image.component';
import {CommonModule} from '@angular/common';
import { WithSocialHeaderFooterComponent } from './with-social-header-footer/with-social-header-footer.component';
import {SharedModule} from '../../shared/shared.module';
import {LoginLayoutComponent} from './login-layout.component';
import {LoginRoutingModule} from './login-routing.module';
import {StudentLoginComponent} from './students/student-login.component';
import {GuardianLoginComponent} from './guardian/guardian-login.component';
import {TeacherLoginComponent} from './teachers/teacher-login.component';

@NgModule({
    imports: [
        CommonModule,
       LoginRoutingModule,
        SharedModule
    ],
    declarations: [LoginLayoutComponent,
      WithBgImageComponent,
      WithHeaderFooterComponent,
      WithSocialComponent,
      WithSocialHeaderFooterComponent,
      StudentLoginComponent,
      GuardianLoginComponent,
      TeacherLoginComponent]
})

export class LoginModule {}
