/**
 * Created by LifeSoft on 05/12/17.
 */
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginRoutes} from './login.routing';
@NgModule({
  imports: [RouterModule.forChild(LoginRoutes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }

