import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
/**
 * Created by LifeSoft on 30/10/17.
 */
@Injectable()
export class EmployeeGaurd implements CanActivate, CanActivateChild {
    constructor(private router: Router, private _auth: AuthService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url = state.url;
        return this.checkLogin(url);
    }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
    checkLogin(url: string): boolean {
        if (this._auth.isLogin() && this._auth.hasSameUserType('employee')) {
            return true;
        }
        this._auth.redirectUrl = url;
        this.router.navigate(['/login']);
        return false;
    }
}
