import { Component, OnInit } from '@angular/core';
// import { Title } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { User } from '../../../../models/user';
import { AuthService } from '../../../../shared/services/auth.service';

// import { fadeInOutTranslate } from 'src/app/shared/elements/animation';
// import {Guardian} from '../../../../models/guardian';
// import { fadeInOutTranslate } from '../../../../../shared/elements/animation';


@Component({
	selector: 'app-employee-payroll-items',
	templateUrl: './employee-payroll-items.component.html',
	styleUrls: ['./employee-payroll-items.component.css'],
	// animations: [fadeInOutTranslate]
})
export class EmployeePayrollItemsComponent implements OnInit {
	title = 'Employee Payroll Items';
	safeSrc: SafeResourceUrl;
	private url = `${environment.main_url}react/index.html?page=${window.location.pathname}`
	loginUser: User;

	constructor(
		private _title: Title,
		private sanitizer: DomSanitizer,
		private _auth: AuthService,
	) {
		this.loginUser = this._auth.getLoginUser();
		this.url += `&token=${this.loginUser.key}`
		this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
	}

	ngOnInit() {
		// this._title.setTitle(this.title);
		// this.loginUser = this._auth.getLoginUser();
	}


}
