/**
 * Created by LifeSoft on 08/02/18.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {TeacherLayoutComponent} from './teacher-layout.component';
import {HttpClientModule} from '@angular/common/http';
import {TeacherRoutingModule} from './teacher-routing.module';
import {TeacherDashboardComponent} from './dashboard/teacher-dashboard.component';
import {TeacherAccountSettingsComponent} from './settings/teacher-account-settings.component';
import {TeacherLearnerMaterialComponent} from './teacher-learner-material/teacher-learner-material.component';
@NgModule({
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    SharedModule,
    TeacherRoutingModule,
    HttpClientModule],
  declarations: [TeacherLayoutComponent, TeacherDashboardComponent,
                 TeacherAccountSettingsComponent,
                TeacherLearnerMaterialComponent]
})
export class TeacherLayoutModule { }
