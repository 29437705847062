import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
// import { fadeInOutTranslate } from 'src/app/shared/elements/animation';
// import {Guardian} from '../../../../models/guardian';
// import { fadeInOutTranslate } from '../../../../../shared/elements/animation';
import { environment } from '../../../../environments/environment';
import { User } from '../../../models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { MicService } from '../../../models/util/mic.service';


@Component({
	selector: 'app-generate-daily-activity',
	templateUrl: './daily-activity.component.html',
	styleUrls: ['./daily-activity.component.css'],
	// animations: [fadeInOutTranslate]
})
export class StudentDailyActivityComponent implements OnInit {
	title = 'Daily Actvity Report';
	safeSrc: SafeResourceUrl;
	private url = `${environment.main_url}react/index.html`
	loginUser: User;
	defaultWardId = 0;



	constructor(
		private _title: Title,
		private sanitizer: DomSanitizer,
		private micService: MicService,
		private _auth: AuthService
	) {
		this.loginUser = this._auth.getLoginUser();
		this.url += `?page=${window.location.pathname}/${this.micService.getDefaultStudent()}/&token=${this.loginUser.key}`;
		this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
		this.defaultWardId = this.micService.getDefaultStudent();
	}

	ngOnInit() {
		console.log(this.url)
		// this.loginUser = this._auth.getLoginUser();
		// this._title.setTitle(this.title);
	}

}
