import { DataService } from './../../shared/services/util/data.service';
import {
  Component, ElementRef, OnInit, ViewChild, ViewEncapsulation,
  OnDestroy, ContentChild
} from '@angular/core';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {MenuItems} from '../../shared/menu-items/menu-items';
import {AuthService} from '../../shared/services/auth.service';
import {User} from '../../models/user';
import {MicService} from '../../models/util/mic.service';
import {environment} from '../../../environments/environment';
import {RoleManagerService} from '../../shared/services/role-manager.service';
import {DefaultApiService} from '../../shared/services/default-api.service';
import {EduNotification} from '../../models/edu-notification.model';
import {DefaultHeaderService} from '../../shared/services/util/default-header.service';
import {Router} from '@angular/router';
import {EduNotificationService} from '../../shared/services/util/edu-notification.service';

@Component({
    selector: 'app-employees-layout',
    templateUrl: './employees-layout.component.html',
    styleUrls: ['./employees-layout.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('slideOnOff', [
      state('on', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('off', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('on => off', animate('400ms ease-in-out')),
      transition('off => on', animate('400ms ease-in-out'))
    ]),
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('400ms ease-in-out')
      ])
    ])
  ],
  providers: [EduNotificationService]
})
export class EmployeesLayoutComponent implements OnInit, OnDestroy {
  deviceType = 'desktop';
  verticalNavType = 'expanded';
  verticalEffect = 'shrink';
  chatToggle = 'out';
  chatInnerToggle = 'off';
  innerHeight: string;
  isScrolled = false;
  isCollapsedMobile = 'no-block';
  toggleOn = true;
  windowWidth: number;
  loginUser: User;
  base_url = environment.base_url;
  notifications: EduNotification[];
  unreadNotifications = 0;
  @ViewChild('searchFriends') search_friends: ElementRef;
  @ViewChild('toggleButton') toggle_button: ElementRef;
  @ViewChild('sideMenu') side_menu: ElementRef;
  constructor(public menuItems: MenuItems,
              private _auth: AuthService,
              private micService: MicService,
              private roleService: RoleManagerService,
              private defaultApi: DefaultApiService,
              private _defaultHeaders: DefaultHeaderService,
              private router: Router,
              private eduNotificationService: EduNotificationService,
              private dataService: DataService) {
    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + 'px';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributs(this.windowWidth);
  }
  ngOnInit() {
    if (this._auth.isLogin()) {
      this.loginUser = this._auth.getLoginUser();
      this.getNotifications();
    }
    // authEndpoint: this.base_url + 'broadcasting/auth',
    //  this.laravelEcho = new Echo({
    //     authEndpoint: this.base_url + 'api/v1/broadcasting/auth',
    //     broadcaster: 'pusher',
    //     key: '9c6932dea8cc3618d843',
    //     cluster: 'eu',
    //     encrypted: true,
    //      auth: {
    //       headers: {
    //         Authorization: this.loginUser.key
    //       },
    //      }
    //   });

    // this.laravelEcho.private('App.User.' + this.loginUser.id)
    //   .notification((data) => {
    //     if (data.type === "App\\Notifications\\EduReadNotification"){
    //       if (this.notifications.length > 0) {
    //         const index = this.findNotificationIndex(data.id);
    //         if (index > -1){
    //           this.notifications.splice(index,1);
    //         }
    //       }

    //       if (this.unreadNotifications > 0) {
    //         this.unreadNotifications -= 1;
    //       }

    //     } else if (data.type === "App\\Notifications\\EduNotification"){
    //       if (this.notifications) {
    //         this.eduNotificationService.announce(data.eduNotification);
    //         if (this.notifications.length >= 3) {
    //           this.notifications.pop();
    //         }
    //         this.notifications.unshift(data.eduNotification);
    //       } else {
    //         this.notifications = [];
    //         this.notifications.unshift(data.eduNotification);
    //       }
    //       this.unreadNotifications += 1;
    //     }
    //   });
  }
  findNotificationIndex(_id: number): number {
    if (!this.notifications) {
      return -1;
    }
    return this.notifications.findIndex((notification) => notification.id === _id);
  }
  ngOnDestroy(): void {
    // this.laravelEcho.leave('App.User.' + this.loginUser.id);
  }
  viewNotifications(): void {
    this.router.navigate(['/employees/messaging-center/notifications/read']);
  }
  viewNotification(id: number): void {
    this.router.navigate(['/employees/messaging-center/notifications/read', {id: id}]);
  }
  getNotifications(): void {
    this.defaultApi.getAll('api/v1/edu-notifications/get-user-unread-notifications')
      .subscribe((response) => {
        if (response.status) {
          this.notifications = response.data.notifications;
          this.unreadNotifications = response.data.unread;
        }
      })
  }
  onClickedOutside(e: Event) {
    if (this.windowWidth < 768 && this.toggleOn && this.verticalNavType !== 'offcanvas') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
    }
  }

  onResize(event) {
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    this.setMenuAttributs(this.windowWidth);
  }

  setMenuAttributs(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
      this.verticalNavType = 'collapsed';
      this.verticalEffect = 'push';
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.deviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  searchFriendList(event) {
    const search = (this.search_friends.nativeElement.value).toLowerCase();
    let search_input: string;
    let search_parent: any;
    const friendList = document.querySelectorAll('.userlist-box .media-body .chat-header');
    Array.prototype.forEach.call(friendList, function (elements, index) {
      search_input = (elements.innerHTML).toLowerCase();
      search_parent = (elements.parentNode).parentNode;
      if (search_input.indexOf(search) !== -1) {
        search_parent.classList.add('show');
        search_parent.classList.remove('hide');
      } else {
        search_parent.classList.add('hide');
        search_parent.classList.remove('show');
      }
    });
  }

  toggleChat() {
    this.chatToggle = this.chatToggle === 'out' ? 'in' : 'out';
  }

  toggleChatInner() {
    this.chatInnerToggle = this.chatInnerToggle === 'off' ? 'on' : 'off';
  }
  onSendMessage(): void {
    alert('Nice one');
  }
  onAttachFile(): void {
    alert('File Going.');
  }
  toggleOpened() {
    if (this.windowWidth < 768) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
    } else {
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
    }
  }

  onMobileMenu() {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }

  onScroll(event) {
    this.isScrolled = false;
  }
  onLogout(): void {
    this.dataService.resetData();
    this._auth.logout();
  }
  getFullname(): string {
    return this.micService.getFullNameDefault(this.loginUser);
  }
  hasRole($roleName: string): boolean {
    return this.roleService.authHasRole($roleName);
  }
}
