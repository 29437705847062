import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {RoleManagerService} from '../../services/role-manager.service';
import {NotificationsService} from 'angular2-notifications';

@Injectable()
export class ExaminationGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router,
              private roleService: RoleManagerService,
              private notificationService: NotificationsService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.roleService.authHasRole('Examination')){
      return true;
    }
    this.notificationService.warn('Permission Denied', 'You Cannot Visit Page.');
    return false;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
