/**
 * Created by LifeSoft on 14/11/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DefaultHeaderService} from './util/default-header.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {EduClassBody} from '../../models/edu-class';
import {AssignSubjectClassBody} from '../../models/subject';
@Injectable()
export class ClassesService {
    base_url = environment.base_url;
    constructor(private  http: HttpClient,
                private header: DefaultHeaderService) { }
    getActiveClasses(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/classes?active=1', {headers: this.header.getDefaultAuthHeaders()});
    }
    getClasses(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/classes', {headers: this.header.getDefaultAuthHeaders()});
    }
    getLeanClasses(): Observable<any> {
      return this.http.get(this.base_url + 'api/v1/classes/active', {headers: this.header.getDefaultAuthHeaders()});
    }

    geAlltLeanClasses(): Observable<any> {
      return this.http.get(this.base_url + 'api/v1/classes/lean', {headers: this.header.getDefaultAuthHeaders()});
    }

    withBasicModels(): Observable<any> {
      return this.http.get(this.base_url + 'api/v1/classes/with-basic-models', {headers: this.header.getDefaultAuthHeaders()});
    }

    getClass(id: number): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/classes/' + id,
            {headers: this.header.getDefaultAuthHeaders()});
    }
    saveClass(body: EduClassBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/classes/store', JSON.stringify(body), {
            headers: this.header.getDefaultAuthHeaders()
        });
    }
    assignClassSubject(body: AssignSubjectClassBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/classes/assign-subjects', JSON.stringify(body), {
            headers: this.header.getDefaultAuthHeaders()
        });
    }
    updateClass(id: number, body: EduClassBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/classes/update/' + id, JSON.stringify(body), {
            headers: this.header.getDefaultAuthHeaders()
        });
    }
}
