import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { SnackbarService } from 'ngx-snackbar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  options = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: false,
    maxLength: 0,
    maxStack: 2,
    showProgressBar: false
  };
  constructor(private swUpdate: SwUpdate,
              private snackbarService: SnackbarService) {
    // check for update
    this.swUpdate.available.subscribe((update) => {
      this.snackbarService.add({
        msg: 'New Update Available, It Only takes a Few Seconds to Update',
        action: {
          text: 'Update',
          onClick: (snack) => {
            window.location.reload();
          },
        },
      });
    });
  }
}
