/**
 * Created by LifeSoft on 06/12/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DefaultHeaderService} from './util/default-header.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
@Injectable()
export class EmployeeAnalyticsService {
  base_url = environment.base_url;
  constructor(private http: HttpClient,
              private header: DefaultHeaderService) { }
  basicAnalytics(): Observable<any> {
    return this.http.get(this.base_url + 'api/v1/employees-analytics/basic',
                        {headers: this.header.getDefaultAuthHeaders()});
  }
}

