/**
 * Created by LifeSoft on 09/01/18.
 */
import { Component, OnInit } from '@angular/core';
import {NotificationsService, NotificationType} from 'angular2-notifications';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidateFormService} from '../../../shared/services/validate-form.service';
import {AuthService} from '../../../shared/services/auth.service';
import {RedirectService} from '../../../shared/services/redirect.service';
import {RoleManagerService} from '../../../shared/services/role-manager.service';
import {LoginUserBody} from '../../../models/login-user-body';
import {ErrorService} from '../../../shared/services/util/error.service';
import {Title} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MicService } from 'src/app/models/util/mic.service';

@Component({
  selector: 'app-student-login',
  templateUrl: './student-login.component.html'
})
export class StudentLoginComponent implements OnInit {
  title = 'Student Login';
  loading = false;
  loginForm: FormGroup;
  userType = 'student';
  background: object;
  baseUrl = environment.base_url;
  constructor(private fb: FormBuilder,
              private formValidator: ValidateFormService,
              private _auth: AuthService,
              private redirect: RedirectService,
              private roleManager: RoleManagerService,
              private notificationService: NotificationsService,
              private errorService: ErrorService,
              private _title: Title,
              private micService: MicService) { }

  ngOnInit() {
    const top = this.baseUrl.split('.')[0] || '';
    this.background = this.micService.getBackgroundCSS(top);
    this._title.setTitle(this.title);
    if (this._auth.isLogin()) {
      if (this._auth.hasSameUserType(this.userType)) {
        this.notificationService.html('Welcome Back!<br>Looks like You Didn\'t Log Out!', NotificationType.Info);
        this.redirect.onLoginRedirect(this.userType);
      } else {
        this.onSameTypeMisMatch();
      }
    }
    this.createForm();
  }
  onSameTypeMisMatch(): void {
    this.notificationService.warn(this.title, 'Please Check Where You Are Going.');
    this._auth.logout();
  }
  createForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  formLabelError(formControl: AbstractControl): string {
    return this.formValidator.complainAboutErrorForLabel(formControl);
  }
  formControlError(formControl: AbstractControl): string {
    return this.formValidator.complainAboutError(formControl);
  }
  buildLoginBody(): LoginUserBody {
    const userBody = new LoginUserBody;
    userBody.password = this.loginForm.get('password').value;
    userBody.username = this.loginForm.get('username').value;
    return userBody;
  }
  onSubmit(): void {
    this.loading = true;
    const userBody = this.buildLoginBody();
    this._auth.attemptLogin(userBody)
      .subscribe((userData) => {
        this.loading = false;
        if (userData.status) {
          this._auth.saveLoginUser(userData.data.user);
          this.roleManager.saveRoles(userData.data.roles);
          if (this._auth.hasSameUserType(this.userType)) {
            this.notificationService.success('Login Successful', 'Welcome Back.');
            if (this._auth.redirectUrl != null) {
              this.redirect.onLoginRedirect(userData.data.user.level, this._auth.redirectUrl);
            } else {
              this.redirect.onLoginRedirect(userData.data.user.level);
            }
          } else {
            this.onSameTypeMisMatch();
          }
        } else {
          this.notificationService
            .warn(this.title, userData.message);
        }
      }, (error) => {
        this.loading = false;
        this.errorService.handleError(error);
      });
  }

}
