/**
 * Created by LifeSoft on 09/01/18.
 */
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {User} from '../../../models/user';
import {AuthService} from '../../../shared/services/auth.service';
import {Subscription} from 'rxjs';
import {DefaultApiService} from '../../../shared/services/default-api.service';
import {
  CurrentStudentAnalyticsResponse,
  StudentAnalyticsResponse
} from '../../../models/response/student-analytics-response';
import {ErrorService} from '../../../shared/services/util/error.service';
import {NotificationsService} from 'angular2-notifications';
import {BarChart} from '../../../models/util/bar-chart';
import {AcademicYear} from '../../../models/academic-year';
import {CurrentAcademicCalendar} from '../../../models/util/current-academic-calendar';
import {EduColorGeneratorService} from '../../../shared/services/util/edu-color-generator.service';
@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html'
})
export class StudentDashboardComponent implements OnInit {
  title = 'Student Dashboard';
  termLabels: string[];
  termData: number[];
  loginUser: User;
  graphBusy = false;
  backgroundColor = ['#36A2EB', '#36A2EB', '#36A2EB'];
  barData: BarChart;
  academicYears: AcademicYear[];
  currentCalendar: CurrentAcademicCalendar;
  options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero:true,
          autoSkip: false
        }
      }],
      xAxes: [{
        barThickness: 70,
      }]
    }
  };
  constructor(private _title: Title,
              private _auth: AuthService,
              private defaultApi: DefaultApiService,
              private errorService: ErrorService,
              private notificationService: NotificationsService,
              private colorGenerator: EduColorGeneratorService) { }
  ngOnInit(): void {
    this._title.setTitle(this.title);
    this.onPageStart();
  }
  onPageStart(): void {
    this.loginUser = this._auth.getLoginUser();
    if (this.loginUser.tags.length === 0){
      this.getCurrentCalendarAnalytics();
    }

  }
  onPageRefresh(refresh: boolean): void {
    if (refresh) {
      this.onPageStart();
    }
  }
  getCurrentCalendarAnalytics(): void {
    const url = 'api/v1/students-analytics/current-calendar-examination-history/' + this.loginUser.details.id;
    this.graphBusy = true;
    this.defaultApi.getById(url)
      .subscribe((response: CurrentStudentAnalyticsResponse) => {
        this.graphBusy = false;
      if (response.status) {
        this.termLabels = response.data.termsLabel;
        this.termData = response.data.averages;
        this.academicYears = response.data.academicYears;
        this.currentCalendar = response.data.currentCalendar;
        this.barData = {
          labels: this.termLabels,
          datasets:  [
              {label: response.data.currentCalendar.academicYear.name + ' Academic Year Examination',
              data: response.data.averages,
              backgroundColor: [this.colorGenerator.generateRandomColor(), this.colorGenerator.generateRandomColor(), this.colorGenerator.generateRandomColor()],
              borderWidth: 2}
              ]
        };
      }else {
        this.notificationService.warn(this.title, 'Failed to get Current Examination Data!');
      }
      }, (error) => {
        this.graphBusy = false;
        this.errorService.handleError(error);
      });
  }
  onAcademicYearSelected(index: number){
    this.getStudentAnalyticsByAcademicYear(this.academicYears[index]);
  }
  getStudentAnalyticsByAcademicYear(academicYear: AcademicYear): void {
    const url = 'api/v1/students-analytics/examination-averages/'
                + this.loginUser.details.id + '/' + academicYear.id;
    this.graphBusy = true;
    this.defaultApi.getById(url)
      .subscribe((response: StudentAnalyticsResponse) => {
        this.graphBusy = false;
        if (response.status) {
          this.barData = {
            labels: this.termLabels,
            datasets:  [
              {label: academicYear.name + ' Academic Year Examination',
                data: response.data.averages,
                backgroundColor: this.backgroundColor,
                borderWidth: 2}
            ]
          };
        }else {
          this.notificationService.warn(this.title, 'Failed to get Current Examination Data!');
        }
      }, (error) => {
        this.graphBusy = false;
        this.errorService.handleError(error);
      });
  }
}
