import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {RoleManagerService} from '../../../shared/services/role-manager.service';
import {EmployeeAnalyticsService} from '../../../shared/services/employee-analytics.service';
import {BasicEmployeeAnalytics } from '../../../models/util/basic-employee-analytics';
import {EduColorGeneratorService} from '../../../shared/services/util/edu-color-generator.service';
import { Router } from '@angular/router';
import {EmployeeService} from '../../../shared/services/employee.service';
import {DefaultApiService} from '../../../shared/services/default-api.service';
import { Subject } from '../../../models/subject';
import { EduTerm } from '../../../models/edu-term';
import { EduClass } from '../../../models/edu-class';
import { PaymentProgress } from '../../../models/payment-progress';
import { AcademicYear } from '../../../models/academic-year';
declare var $: any;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css',
	'../../../../assets/icon/SVG-animated/svg-weather.css'
],
encapsulation: ViewEncapsulation.None
})

export class DashboardComponent implements OnInit {
	title = 'Employees Dashboard';
	yearlyClassAverageTitle = '';
	base_url = environment.base_url;
	basicAnalytics: BasicEmployeeAnalytics;
	paymentType = 'pie';
	paymentData = {
		labels: [],
		datasets: [{
			data: [],
			backgroundColor: [],
			// hoverBackgroundColor: []
		}]
	};
	paymentOptions = {
		responsive: true,
		maintainAspectRatio: false,
	};

	areaChartOption = {
		low: 0,
		showArea: true
	};
	options = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				ticks: {
					autoSkip: false
				},
				minBarThickness: 200
			}]
		}
	};
	comboOptions = {
		height: 470,
		title: 'Class Termly Averages',
		vAxis: { title: 'Average' },
		hAxis: { title: 'Class' },
		seriesType: 'bars',
		bar: {groupWidth: '90%'},
		colors: ['#e74c3c', '#2ecc71', '#5faee3']
	};

	// series: { 5: { type: 'line' } },
	areaChartData: any;
	subjectStudentChart: any;
	loadingSubjectStudentChart: boolean = false;
	selectedClassSubjects: Subject[] = [];
	terms: EduTerm[] = [];
	eduClasses: EduClass[] = [];
	academicYears: AcademicYear[] = [];
	selectedSubject: Subject;
	selectedTerm: EduTerm;
	selectedEduClass: EduClass;
	selectedAcademicYear: AcademicYear;
	paymentProgress: PaymentProgress;
	// options: {
		// 	responsive: true,
		// 	maintainAspectRatio: false
		// }
		yearlyChartData: any;
		constructor(private _title: Title,
			private defaultApi: DefaultApiService,
			private roleService: RoleManagerService,
			private analyticsService: EmployeeAnalyticsService,
			private colorGenerator: EduColorGeneratorService,
			private employeeService: EmployeeService,
			private router: Router) {}

			ngOnInit() {
				this._title.setTitle(this.title);
				this.getBasicAnalytics();
				this.getPaymentProgress();
				this.fetchSubjectStudentChartBasicData();

			}
			hasRole($roleName: string): boolean {
				return this.roleService.authHasRole($roleName);
			}
			fetchSubjectStudentChartBasicData() {
				// fetch terms,
				// fetch edu classes with subjects
				// fetch academic years
				this.employeeService.getDashboardSubjectStudentChartData().subscribe(res => {
					if (res.status) {
						this.selectedTerm = res.data.terms.find(t => t.id == res.data.currentAcademicYear.term_id);
						this.selectedAcademicYear = res.data.academicYears.find(t => t.id == res.data.currentAcademicYear.academic_year_id);
						// document.querySelector("select.student-subject-analytics[name='academic_year_id']")['value'] = this.selectedAcademicYear.id;
						// document.querySelector("select.student-subject-analytics[name='term_id']")['value'] = this.selectedTerm.id;
						this.terms = res.data.terms;
						this.academicYears = res.data.academicYears;
						this.eduClasses = res.data.eduClasses;
					}
				});
			}
			fetchSubjectStudentChartAnalytics(edu_class_id, subject_id, academic_year_id, term_id) {
				this.loadingSubjectStudentChart = true;
				this.employeeService.getDashboardSubjectStudentChartAnalytics(edu_class_id, subject_id, academic_year_id, term_id).subscribe(res => {
					if(res.status) {
						this.drawSubjectStudentChart(res.data);
						this.loadingSubjectStudentChart = false;
					}
				});
			}
			onEduClassChange(e) {
				this.selectedEduClass = this.eduClasses.find(c => c.id == e.target.value);
				this.selectedSubject = null;
				this.selectedClassSubjects = null;
				this.drawSubjectStudentChart({data: [], subject: null});

				this.selectedClassSubjects = this.selectedEduClass.currentSubjects;
			}
			onSubjectChange(e) {
				this.drawSubjectStudentChart({data: [], subject: null});
				// set selected subject
				this.selectedSubject = this.selectedClassSubjects.find(s => s.id == e.target.value);

				// fetch student scores for
				this.fetchSubjectStudentChartAnalytics(this.selectedEduClass.id, this.selectedSubject.id, this.selectedAcademicYear.id, this.selectedTerm.id);
			}
			onTermChange(e) {
				this.drawSubjectStudentChart({data: [], subject: null});
				// set selected term
				this.selectedTerm = this.terms.find(t => t.id == e.target.value);

				// make conditionally disabled


				if(this.selectedEduClass && this.selectedSubject) {
					// fetch student scores for
					this.fetchSubjectStudentChartAnalytics(this.selectedEduClass.id, this.selectedSubject.id, this.selectedAcademicYear.id, this.selectedTerm.id);
				}

			}
			onAcademicYearChange(e) {
				this.drawSubjectStudentChart({data: [], subject: null});
				this.selectedAcademicYear = this.academicYears.find(t => t.id == e.target.value);

				// make conditionally disabled


				if(this.selectedEduClass && this.selectedSubject) {
					// fetch student scores for
					this.fetchSubjectStudentChartAnalytics(this.selectedEduClass.id, this.selectedSubject.id, this.selectedAcademicYear.id, this.selectedTerm.id);
				}
			}
			drawSubjectStudentChart(apiData: { data: {name, value}[], subject: Subject | null }) {
				const subject = apiData.subject;
				const _data = apiData.data;
				const labels: string[] = [];
				const data: number[] = [];
				const backgroundColor: string[] = [];
				const borderColor: string[] = [];

				_data.forEach(d => {
					labels.push(d.name);
					data.push(d.value);
					const color = this.colorGenerator.generateRandomWithBorderColor();
					backgroundColor.push(color[0]);
					borderColor.push(color[1]);
				});

				this.subjectStudentChart = {
					labels,
					datasets: [
						{
							label: subject ? subject.name+' Score' : '',
							backgroundColor,
							borderColor,
							data
						}
					]
				};

			}
			getBasicAnalytics(): void {
				this.analyticsService.basicAnalytics().subscribe((response) => {
					if (response.status) {
						this.basicAnalytics = response.data;
						this.drawYearlyAverageChart(this.basicAnalytics);
						this.drawAreaChart(response.data.classListData);
					}
				});
			}
			getPaymentProgress(): void {
				const url = 'api/v1/dashboard/payment-progress';
				this.defaultApi.getAll(url).subscribe((response) => {
					if (response.status) {
						this.paymentProgress = response.data.paymentProgress;
						this.paymentData = {
							labels: [
								'Current Bills Amount',
								'Current Payments Amount',
								'Current Discount Amount',
								'Previous Debit Amount',
								'Previous Credit Amount',
								'Outstanding Amount',
							],
							datasets: [
								{
									data: [
										this.paymentProgress.current_bill_amount,
										this.paymentProgress.current_payment_amount,
										this.paymentProgress.current_discount_amount,
										this.paymentProgress.previous_debit_amount,
										this.paymentProgress.previous_credit_amount,
										this.paymentProgress.outstanding_amount,
									],
									backgroundColor: [
										'#25A6F7',
										'#FB9A7D',
										'#01C0C8',
										'#f4d03f',
										'#e83023',
										'#44308c',
									],
									// hoverBackgroundColor: [
									// 	'#6cc4fb',
									// 	'#ffb59f',
									// 	'#0dedf7',
									// 	'#6cc4fb',
									// 	'#ffb59f',
									// 	'#0dedf7',
									// ]
								}
							]
						};

					}
				});
			}

			drawYearlyAverageChart(_data: BasicEmployeeAnalytics): void {
				this.yearlyClassAverageTitle = _data.currentCalendar ? `Result For ${_data.currentCalendar.academicYear.name} Academic Year` : '';

				let labels = ['Classes','First Term','Second Term','Third Term'];

				let dataTable = [];
				dataTable.push(labels);

				for(let i=0; i< _data.classTermAverages.length; i++) {
					let _dataTable = [_data.classTermAverages[i].class,
					..._data.classTermAverages[i].value];
					dataTable.push(_dataTable);
				}

				this.yearlyChartData = {
					chartType: 'ComboChart',
					dataTable: dataTable,
					options: this.comboOptions
				}

			}

			drawAreaChart(_data: any): void {
				let labels = [];
				let data = [];
				let backgroundColor = [];
				let borderColor = [];
				for(let i=0; i< _data.length; i++) {
					labels.push(_data[i].name);
					data.push(_data[i].value);
					const color = this.colorGenerator.generateRandomWithBorderColor();
					backgroundColor.push(color[0]);
					borderColor.push(color[1]);
				}

				this.areaChartData = {
					labels,
					datasets: [{
						label: 'Class List',
						backgroundColor,
						borderColor,
						data,
						borderWidth: 2
					}]
				}

			}
			getTermName(index: number): string {
				switch(index){
					case 0:
					return "FIRST";
					case 1:
					return "SECOND";
					case 2:
					return "THIRD";
					default:
					return ""
				}
			}

			goToActiveStudents(): void {
				this.router.navigate(['/employees/students/active-class-list']).catch((error) => {console.log(error)});
			}
		}
