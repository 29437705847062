/**
 * Created by LifeSoft on 09/02/18.
 */
import {Component, OnInit} from '@angular/core';
import {ErrorService} from '../../../shared/services/util/error.service';
import {NotificationsService} from 'angular2-notifications';
import {DefaultApiService} from '../../../shared/services/default-api.service';
import {EduClass} from '../../../models/edu-class';
import {Subscription} from 'rxjs';
import {CurrentAcademicCalendar} from '../../../models/util/current-academic-calendar';
import {TeacherDashboardResponse} from '../../../models/response/teacher-dashboard-response';
import {Title} from '@angular/platform-browser';
import {User} from '../../../models/user';
import {AuthService} from '../../../shared/services/auth.service';
import {EmployeeSubject} from '../../../models/employee-subject';
import { saveAs } from 'file-saver/FileSaver';
@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html'
})
export class TeacherDashboardComponent implements OnInit {
  title = 'Teachers Dashboard';
  assignedClasses: EduClass[];
  employeeSubjects: EmployeeSubject[];
  loading = false;
  currentCalendar: CurrentAcademicCalendar;
  loginUser: User;
  constructor(private errorService: ErrorService,
              private notificationService: NotificationsService,
              private defaultApi: DefaultApiService,
              private _title: Title,
              private _auth: AuthService) { }
  ngOnInit(): void {
    this._title.setTitle(this.title);
    this.loginUser = this._auth.getLoginUser();
    this.getTeacherDashboardData();
  }
  onPageStart(): void {
    this.getTeacherDashboardData();
  }
  onPageRefresh(refresh: boolean): void {
    if (refresh){
      this.onPageStart();
    }
  }
  getTeacherDashboardData(): void {
    if (this.loginUser.details) {
      const url = 'api/v1/teachers/get-teacher-dashboard-data/' + this.loginUser.details.id;
      this.loading = true;
      this.defaultApi.getAll(url)
        .subscribe((response: TeacherDashboardResponse) => {
          this.loading = false;
          if (response.status){
            this.assignedClasses = response.data.classes;
            this.employeeSubjects = response.data.employeeSubjects;
            this.currentCalendar = response.data.currentCalendar;
          }else {
            this.notificationService.warn(this.title, response.message);
          }
        }, (error) => {
          this.loading = false;
          this.errorService.handleError(error);
        });
    }else {
      this.notificationService.warn(this.title, 'This Page is For Teachers Only');
    }
  }
  onGenearateClassList(eduClass: EduClass): void {
    const url = 'api/v1/teachers/generate-class-list/' + eduClass.id;
    this.loading = true;
    this.defaultApi.getFile(url)
                    .subscribe((response) => {
                        this.loading = false;
                        if (response.type === 'application/pdf') {
                          this.notificationService.success(this.title, 'Report Generated Successfully!');
                          const fileName = eduClass.name + ' List.pdf';
                          saveAs(response, fileName);
                        }else {
                          this.notificationService.warn(this.title, 'Failed to Generate Class List');
                        }
                    }, (error) =>{
                      this.loading = false;
                      this.errorService.handleError(error);
                    });
  }
}
