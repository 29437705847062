/**
 * Created by LifeSoft on 28/11/17.
 */
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {DefaultHeaderService} from './util/default-header.service';
@Injectable()
export class DefaultApiService {
    base_url = environment.base_url;
    constructor(private http: HttpClient,
                private header: DefaultHeaderService) { }
    getActives(url: string): Observable<any> {
        return this.http.get(this.base_url + url,
            {headers: this.header.getDefaultAuthHeaders()});
    }
    getAll(url: string): Observable<any> {
        return this.http.get(this.base_url + url,
            {headers: this.header.getDefaultAuthHeaders()});
    }
    getWithParams(url: string, params): Observable<any> {
      const httpParams = params === null ? {} : this.params(params);
      return this.http.get(this.base_url + url,
          {headers: this.header.getDefaultAuthHeaders(), params: httpParams});
    }
    getById(url: string): Observable<any> {
        return this.http.get(this.base_url + url,
            {headers: this.header.getDefaultAuthHeaders()});
    }
    save(body: any, url: string): Observable<any> {
        let header;
         if (body instanceof FormData) {
           header = this.header.getDefaultAuthMultiHeaders();
         } else {
             body = JSON.stringify(body);
             header = this.header.getDefaultAuthHeaders();
         }
        return this.http.post(this.base_url + url, body,
            {headers: header});
    }
    deleteItem(url: string): Observable<any> {
      return this.http.delete(this.base_url + url,
        {headers: this.header.getDefaultAuthHeaders()});
    }
    formDataUpdate( body: FormData, url: string): Observable<any> {
       const header = this.header.getDefaultAuthMultiHeaders();
        return this.http.post(this.base_url + url, body,
            {headers: header});
    }
    update( body: any, url: string): Observable<any> {
        const header = this.header.getDefaultAuthHeaders();
        return this.http.put(this.base_url + url, JSON.stringify(body),
            {headers: header});
    }
  getFile(url: string): Observable<Blob> {
    return this.http.get(this.base_url + url,
      {headers: this.header.getDefaultAuthBlobHeaders(), responseType: 'blob'});
  }
  getFileWithParams(url: string, params): Observable<Blob> {
    const httpParams = params === null ? {} : this.params(params);
    return this.http.get(this.base_url + url,
      {headers: this.header.getDefaultAuthBlobHeaders(), responseType: 'blob', params: httpParams});
  }
  postToGetFile(body: any, url: string): Observable<Blob> {
    return this.http.post(this.base_url + url, body,
      {headers: this.header.getDefaultAuthBlobHeaders(), responseType: 'blob'});
  }
  getFileAdvance(url: string): Observable<any> {
    return this.http.get(this.base_url + url,
      {headers: this.header.getDefaultAuthBlobHeaders(), responseType: 'blob'});
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('Client');
      console.log(error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log('Server');
      console.log(typeof(error));
    }

    // return an observable with a user-facing error message
    return throwError(
      'Something bad always Happens.');
  }
  params(params: object): HttpParams {
    let httpParam = new HttpParams();
    Object.keys(params).forEach((key) => {
      httpParam = httpParam.append(key, params[key]);
    });
    return httpParam;
  }
}
