import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-error4',
  templateUrl: './error4.component.html',
  styleUrls: ['./error4.component.css']
})
export class Error4Component implements OnInit {
  title = 'Page Not Found!';
  constructor(private locationService: Location,
              private _title: Title) { }
  ngOnInit(): void {
    this._title.setTitle(this.title);
  }
  public goBack(): void {
    this.locationService.back();
  }
}
