import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ISMSBody } from 'src/app/models/sms.model';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ValidateFormService } from '../services/validate-form.service';


@Component({
  selector: 'app-basic-sms',
  templateUrl: './basic-sms.component.html',
  styleUrls: ['./basic-sms.component.css']
})
export class BasicSmsComponent implements OnInit, OnChanges {
  @Input() title = 'Send SMS';
  @Input() message = '';
  @Input() contact: string;
  @Output() smsBody = new EventEmitter<ISMSBody>();
  smsForm: FormGroup;
  totalAllowedCharacters = 160;
  constructor(private formBuilder: FormBuilder,
              private formValidator: ValidateFormService) { }

  ngOnInit() {
    this.smsForm = this.formBuilder.group({
      contact: [this.contact, [Validators.required, Validators.minLength(12)]],
      message: [this.message, [Validators.required]]
    });
  }
  ngOnChanges(changes) {
    if (this.smsForm) {
      const contact = changes.contact && changes.contact.currentValue ? changes.contact.currentValue : '';
      const message = changes.message && changes.message.currentValue ? changes.message.currentValue : '';
      this.smsForm.patchValue({
        contact,
        message
      });
    }
  }
  get messageCount(): number {
    if (this.smsForm) {
      return this.smsForm.get('message').value.length;
    }
    return 0;
  }
  get numberOfMessages(): number {
    if (this.smsForm) {
      return Math.ceil(this.smsForm.get('message').value.length / this.totalAllowedCharacters);
    }
    return 0;
  }
  get messageContent(): string {
    if (this.smsForm) {
      return this.smsForm.get('message').value;
    }
    return '';
  }
  getFormControl(field: string): AbstractControl {
    return this.smsForm.get(field);
  }
  displayLabelError(field: string): string {
    return this.formValidator.complainAboutErrorForLabel(this.getFormControl(field));
  }
  displayControlError(field: string): string {
    return this.formValidator.complainAboutError(this.getFormControl(field));
  }
  isFieldValid(field: string): boolean {
    return this.formValidator.isInputValid(this.getFormControl(field));
  }
  onValidateForm(): void {
    Object.keys(this.smsForm.controls).forEach((field) => {
      const control = this.smsForm.get(field);
      control.markAsTouched({onlySelf: true});
    });
  }
  onSubmit(): void {
    if (this.smsForm.valid) {
      const body = this.smsForm.getRawValue();
      this.smsBody.emit(body);
    } else {
      this.onValidateForm();
    }

  }

}
