/**
 * Created by LifeSoft on 09/01/18.
 */
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MicService } from '../../../models/util/mic.service';
import { User } from '../../../models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { SubjectService } from '../../../shared/services/subject.service';
import { EmployeeService } from '../../../shared/services/employee.service';
import { DefaultApiService } from '../../../shared/services/default-api.service';
import { Employee, GetEmployeeResponse } from '../../../models/employee';
import { ErrorService } from '../../../shared/services/util/error.service';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
@Component({
	selector: 'app-employee-profile',
	templateUrl: './employee-profile.component.html'
})
export class EmployeeProfileComponent implements OnInit {
	title = 'Employee Profile';
	cardSubTitle = '';
	loginUser: User;
	profileBusy = false;
	employee: Employee;
	user: User;
	constructor(private _title: Title,
		private micService: MicService,
		private _auth: AuthService,
		private defaultApi: DefaultApiService,
		private errorService: ErrorService,
		private notificationService: NotificationsService) { }
	ngOnInit(): void {
		this._title.setTitle(this.title);
		this.onPageStart();
	}
	onPageStart(): void {
		this.loginUser = this._auth.getLoginUser();
		this.getEmployeeByUserId();
	}
	onPageRefresh(refresh): void {
		if (refresh) {
			this.onPageStart();
		}
	}
	getFullName(model: any): string {
		return this.micService.getFullname(model);
	}
	getFullNameDefault(model: any): string {
		return this.micService.getFullNameDefault(model)
	}
	getEmployeeByUserId(): void {
		const url = 'api/v1/users/profile';
		this.profileBusy = true;
		this.defaultApi.getById(url)
			.subscribe((response) => {
				this.profileBusy = false;
				if (response.status) {
					this.user = response.data.user;
					this.employee = response.data.user.employee;
					// this.cardSubTitle = response.data.user.fullName;
				} else {
					this.notificationService.warn(this.title, response.message);
				}
			},
				(error) => {
					this.profileBusy = false;
					this.errorService.handleError(error);
				});
	}
}
