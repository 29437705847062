/**
 * Created by LifeSoft on 21/11/17.
 */
import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { sumBy } from 'lodash';
@Injectable()
export class MicService {
    getFullname(model: any): string {
        const $middleName = model.middle_name ? ' ' + model.middle_name + ' '  : ' ';
        return this.capitalizeFirstLetter(model.last_name +  $middleName + model.first_name);
    }
  getCorrectOrder(model: any): string {
    const $middleName = model.middle_name ? ' ' + model.middle_name + ' '  : ' ';
    return this.capitalizeFirstLetter(model.last_name + ' ' +  model.first_name + $middleName );
  }
  getFullNameDefault(model: any): string {
    const $middleName = model.middle_name ? ' ' + model.middle_name + ' '  : ' ';
    return this.capitalizeFirstLetter(model.first_name +  $middleName + model.last_name);
  }
    slug(str): string {
       return (str.toLowerCase()).split(' ').join('-');
    }
  capitalizeFirstLetter(value: string) {
      value = value.toLowerCase();
    return value.replace(/\b[a-z]/g, function(f) {return f.toUpperCase(); });
  }
  getDateConfig() {
    return { dateInputFormat: 'YYYY-MM-DD',
             rangeInputFormat: 'YYYY-MM-DD',
             containerClass: 'theme-dark-blue',
             showWeekNumbers: false,
             adaptivePosition: true };
  }
  convertDate(date: Date): string {
    const d = new Date(date);
    let  month = '' + (d.getMonth() + 1);
    let  day = '' + d.getDate();
    const  year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('-');
  }

  isDateValid(d) {
    if (Object.prototype.toString.call(d) === '[object Date]') {
      if (isNaN(d.getTime())) {
        return true;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  getDefaultStudent(): number {
      return +localStorage.getItem('ward') || 0;
  }
  getBackgroundCSS(url: string): object {
    if (url === '' || !environment.production) {
      if (!environment.production) {
        const fullURL = '\'' + 'http://edu.test/school-background' + '\'';
        url =  `url(${fullURL}) no-repeat center center fixed`;
      } else {
        return {
          'background-color' : '#0096dd !important;'
        };
      }
    } else {
      const fullURL = '\'' + url + '.edugh.net/school-background' + '\'';
      url =  `url(${fullURL}) no-repeat center center fixed`;
    }
    return {  'background-size': 'cover;',
              'background': url,
               height: '100%;'};
  }
  generateArray(num: number): number[] {
    const arr = [];
    for (let i = 0; i < num; i++) {
      arr.push(i);
    }
    return arr;
  }
  difForHumans(date: Date): string {
    return moment(date).fromNow();
  }
  getTotal(data: Array<any>, column: string): number {
    return sumBy(data, column);
  }
}
