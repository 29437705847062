/**
 * Created by LifeSoft on 21/11/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {DefaultHeaderService} from './util/default-header.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Guardian} from '../../models/guardian';
import {MicService} from '../../models/util/mic.service';
@Injectable()
export class GuardianService {
    base_url = environment.base_url;
    constructor(private http: HttpClient,
                private header: DefaultHeaderService,
                private router: Router,
                private micService: MicService) { }
    getActiveGuardians(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/guardians?active=1',
            {headers: this.header.getDefaultAuthHeaders()});
    }
    getGuardians(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/guardians',
            {headers: this.header.getDefaultAuthHeaders()});
    }
    getGuardianById(id: number): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/guardians/' + id,
            {headers: this.header.getDefaultAuthHeaders()});
    }
    getFullname(guardian: Guardian): string {
        const $middleName = guardian.middle_name ? guardian.middle_name : '';
        return guardian.first_name + ' ' + $middleName + ' ' + guardian.last_name;
    }
    buildWardsString(guardian: Guardian): string {
        if (guardian.wards.length > 0) {
            const last_ward_id = guardian.wards[guardian.wards.length-1].id;
            let student = '';
            for (const ward of guardian.wards) {
                student += last_ward_id !== ward.id ? this.micService.getFullname(ward) +  ', '
                                                      : this.micService.getFullname(ward);
            }
            return student;
        }else {
            return '';
        }
    }
}
