import { DataService } from './services/util/data.service';
import { EduArray } from './services/util/edu-array.service';
import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';

import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import {CardRefreshDirective} from './card/card-refresh.directive';
import {CardToggleDirective} from './card/card-toggle.directive';
import { CardComponent } from './card/card.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ParentRemoveDirective} from './elements/parent-remove.directive';
import {AlertModule, BsDatepickerModule, BsDropdownModule, PaginationModule, ProgressbarModule, AccordionModule} from 'ngx-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SpinnerComponent} from '../spinner/spinner.component';
import {ModalAnimationComponent} from './modal-animation/modal-animation.component';
import {ModalBasicComponent} from './modal-basic/modal-basic.component';
import {ScrollModule} from '../scroll/scroll.module';
import {TagInputModule} from 'ngx-chips';
import {AnimatorModule} from 'css-animator';
import {ColorPickerModule} from 'ngx-color-picker';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {NgxMaskModule} from 'ngx-mask';
import {SelectModule} from 'ng-select';
import {SelectOptionService} from './elements/select-option.service';
import {FormWizardModule} from 'angular2-wizard';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DataFilterPipe} from './elements/data-filter.pipe';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {FileUploadModule} from 'ng2-file-upload';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';
import {UiSwitchModule} from 'ng2-ui-switch/dist';
import {ChartModule} from 'angular2-chartjs';
import {ChartistModule} from 'ng-chartist';
import {DataTableModule} from '@pascalhonegger/ng-datatable';
import {TodoService} from './todo/todo.service';
import {ClickOutsideModule} from 'ng-click-outside';
import {ValidateFormService} from './services/validate-form.service';
import {AuthService} from './services/auth.service';
import {RedirectService} from './services/redirect.service';
import {RoleManagerService} from './services/role-manager.service';
import {AuthGuard} from './guard/auth.guard';
import {DefaultApiService} from './services/default-api.service';
import {DefaultHeaderService} from './services/util/default-header.service';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {TitleComponent} from './title/title.component';
import {RouterModule} from '@angular/router';
import {EmployeeGaurd} from './guard/employee-guard.service';
import {MicService} from '../models/util/mic.service';
import {EmployeeAnalyticsService} from './services/employee-analytics.service';
import {BreadcrumbGeneratorService} from './services/util/breadcrumb-generator.service';
import {EmployeeService} from './services/employee.service';
import {EmployeePositionService} from './services/employee-position.service';
import {EmployeeDepartmentService} from './services/employee-department.service';
import {EmployeeCategoryService} from './services/employee-category.service';
import {EmployeeQaulificationService} from './services/employee-qualification.service';
import {CountryService} from './services/country.service';
import {ClassesService} from './services/classes.service';
import {GradingSystemService} from './services/grading-system.service';
import {EduClassAssessmentService} from './services/edu-class-assessment.service';
import {StudentService} from './services/student.service';
import {TitleService} from './services/util/title.service';
import {GuardianService} from './services/guardian.service';
import {SubjectService} from './services/subject.service';
import {CurrentCalendarService} from './services/util/current-calendar.service';
import {ExcelGeneratorService} from './services/excel-generator.service';
import {ExaminationService} from './services/examination.service';
import {ErrorService} from './services/util/error.service';
import {StudentGuardService} from './guard/student-guard.service';
import {GuardianGuardService} from './guard/guardian-guard.service';
import {EduColorGeneratorService} from './services/util/edu-color-generator.service';
import {ExaminationGuard} from './guard/examination/examination-guard';
import {TeacherGuard} from './guard/teacher-guard.guard';
import {CustomFormsModule} from 'ng2-validation';
import {ANIMATION_TYPES, LoadingModule} from 'ngx-loading';
import {CountdownModule} from 'ngx-countdown';
import {ConfirmDialogService} from './guard/util/confirm-dialog.service';
import { NrenzaValidationComponent } from './nrenza-validation/nrenza-validation.component';
import {BlobJSON} from './services/util/blob-json.util';
import {MomentModule} from 'angular2-moment';
import {TruncatePipe} from './elements/truncate-pipe.pipe';
import { FileSaver } from './services/util/file-saver.service';
import {ActivityResolverService} from './services/util/activity-resolver.service';
import {StudentWithClassResolver} from './services/util/student-with-class-resolver';
import {EduClassWithStudentResolverService} from './services/util/edu-class-with-student-resolver.service';
import {ActiveEduClassStudentResolverService} from './services/util/active-edu-class-with-student-resolver.service';
import {EmployeeResolverService} from './services/util/employee-resolver.service';
import {LeanEmployeeWithResolver} from './services/util/lean-employee-with-resolver.service';
import {UserResolverService} from './services/util/user-resolver.service';
import {ExaminationResolver} from './services/util/examination-resolver.service';
import {EnterClassMarkResolver} from './services/util/enter-class-mark-resolver.service';
import {EnterBatchExaminationResolver} from './services/util/enter-batch-examination-resolver.service';
import {ExaminationIndexResolver} from './services/util/examination-index-resolver.service';
import {BillItemResolver} from './services/util/bill-item-resolver.service';
import {GuardianResolver} from './services/util/guardian-resolver.service';
import {AcademicCalendarResolver} from './services/util/academic-calendar-resolver.service';
import {StudentTranscriptResolver} from './services/util/student-transcript-resolver.service';
import {SnackbarModule} from 'ngx-snackbar';
import { BasicSmsComponent } from './basic-sms/basic-sms.component';
import { SearchStudentComponent } from './search-student/search-student.component';

@NgModule({
  imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      NgbModule,
      RouterModule,
      PaginationModule.forRoot(),
      ScrollModule,
      TagInputModule,
      UiSwitchModule,
      AnimatorModule,
      ColorPickerModule,
      SelectModule,
      NgxMaskModule,
      CurrencyMaskModule,
      FormWizardModule,
      NgxDatatableModule,
      BsDropdownModule.forRoot(),
      BsDatepickerModule.forRoot(),
      DataTableModule,
      FroalaEditorModule.forRoot(),
      FroalaViewModule.forRoot(),
      FileUploadModule,
      ScrollToModule.forRoot(),
      Ng2GoogleChartsModule,
      ChartModule,
      ChartistModule,
      ClickOutsideModule,
      CustomFormsModule,
    LoadingModule.forRoot({
      animationType: ANIMATION_TYPES.threeBounce,
      backdropBackgroundColour: 'rgba(255,255,255,0.7)',
      backdropBorderRadius: '4px',
      primaryColour: '#00f',
      secondaryColour: '#00f',
      tertiaryColour: '#00f'
    }),
    CountdownModule,
    ProgressbarModule.forRoot(),
    AlertModule.forRoot(),
    MomentModule,
    SnackbarModule.forRoot(),
    AccordionModule.forRoot()
  ],
  declarations: [
      AccordionAnchorDirective,
      AccordionLinkDirective,
      AccordionDirective,
      ToggleFullscreenDirective,
      CardRefreshDirective,
      CardToggleDirective,
      ParentRemoveDirective,
      CardComponent,
      SpinnerComponent,
      ModalAnimationComponent,
      ModalBasicComponent,
      DataFilterPipe,
      TruncatePipe,
      BreadcrumbsComponent,
      TitleComponent,
      NrenzaValidationComponent,
      BasicSmsComponent,
      SearchStudentComponent
  ],
  exports: [
      AccordionAnchorDirective,
      AccordionLinkDirective,
      AccordionDirective,
      ToggleFullscreenDirective,
      CardRefreshDirective,
      CardToggleDirective,
      ParentRemoveDirective,
      CardComponent,
      SpinnerComponent,
      NgbModule,
      PaginationModule,
      FormsModule,
      RouterModule,
      ReactiveFormsModule,
      ModalBasicComponent,
      ModalAnimationComponent,
      ScrollModule,
      TagInputModule,
      UiSwitchModule,
      AnimatorModule,
      // DatepickerModule,
      ColorPickerModule,
      SelectModule,
      NgxMaskModule,
      CurrencyMaskModule,
      FormWizardModule,
      NgxDatatableModule,
      DataTableModule,
      DataFilterPipe,
      TruncatePipe,
      FroalaEditorModule,
      FroalaViewModule,
      FileUploadModule,
      ScrollToModule,
      Ng2GoogleChartsModule,
      ChartModule,
      ChartistModule,
      ClickOutsideModule,
      BreadcrumbsComponent,
      TitleComponent,
      BsDropdownModule,
      LoadingModule,
      CountdownModule,
      ProgressbarModule,
      AlertModule,
      NrenzaValidationComponent,
      MomentModule,
      BsDatepickerModule,
      AccordionModule,
      SnackbarModule,
      BasicSmsComponent,
      SearchStudentComponent
  ],
  providers: [
      MenuItems,
      TodoService,
      SelectOptionService,
      AuthGuard, ValidateFormService, AuthService, RedirectService, RoleManagerService,
      DefaultApiService, DefaultHeaderService, EmployeeGaurd, MicService, EmployeeAnalyticsService,
      BreadcrumbGeneratorService, EmployeeService, EmployeePositionService, EmployeeDepartmentService,
      EmployeeCategoryService, EmployeeQaulificationService, CountryService, ClassesService,
      GradingSystemService, EduClassAssessmentService,
      StudentService, TitleService, GuardianService, SubjectService,
      CurrentCalendarService, ExcelGeneratorService, ExaminationService,
      ErrorService,
      StudentGuardService,
      GuardianGuardService,
      EduColorGeneratorService,
      ExaminationGuard,
      TeacherGuard,
      ConfirmDialogService,
      BlobJSON,
      FileSaver,
      EduArray,
      ActivityResolverService, StudentWithClassResolver, EduClassWithStudentResolverService,
      ActiveEduClassStudentResolverService, EmployeeResolverService, LeanEmployeeWithResolver,
      UserResolverService, ExaminationResolver, EnterClassMarkResolver, EnterBatchExaminationResolver,
      ExaminationIndexResolver, BillItemResolver,
      GuardianResolver, AcademicCalendarResolver, StudentTranscriptResolver,
      DataService
  ],
})
export class SharedModule { }
