
import {Injectable} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
/**
 * Created by LifeSoft on 27/10/17.
 */
@Injectable()
export class ValidateFormService {
    isInputValid(formName: AbstractControl): boolean {
      return (!(formName.invalid && (formName.touched || formName.dirty)));
    }
    isNotRequiredEmailValid(formName: AbstractControl): boolean {
        return ((formName.pristine) || formName.valid);
    }
    complainAboutError(formName: AbstractControl): string {
        if (!this.isInputValid(formName)) {
            return 'form-control-danger';
        }
        return '';
    }
    complainAboutErrorForLabel(formName: AbstractControl): string {
        if (!this.isInputValid(formName)) {
            return 'has-danger';
        }
        return '';
    }
}
