/**
 * Created by LifeSoft on 14/12/17.
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DefaultHeaderService} from './util/default-header.service';
import {environment} from '../../../environments/environment';
import {EmptyMockScoreSheetBody, EmptyScoreSheetBody} from '../../models/empty-score-sheet-body';
@Injectable()
export class ExcelGeneratorService {
  base_url = environment.base_url;
  constructor(private http: HttpClient, private header: DefaultHeaderService) {
  }
  generateEmptyScoreSheet(body: EmptyScoreSheetBody): Observable<any> {
    const url = 'api/v1/excel/score-subject-sheet';
    return this.http.post(this.base_url + url, JSON.stringify(body),
                          {headers: this.header.getDefaultAuthHeaders()});
  }
  generateEmptyMockScoreSheet(body: EmptyMockScoreSheetBody): Observable<any> {
    const url = 'api/v1/excel/mock-score-subject-sheet';
    return this.http.post(this.base_url + url, JSON.stringify(body),
      {headers: this.header.getDefaultAuthHeaders()});
  }
}
