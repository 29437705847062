/**
 * Created by LifeSoft on 29/08/18.
 */
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
@Injectable()
export class WardSelectedAnouncer {
  private wardAnnouncer = new Subject<number>();
  wardID$ = this.wardAnnouncer.asObservable();
  announce(wardId: number): void {
    this.wardAnnouncer.next(wardId);
  }
}
