/**
 * Created by LifeSoft on 28/11/17.
 */
import {Injectable} from '@angular/core';
import {DefaultApiService} from '../default-api.service';
import {Observable} from 'rxjs';
@Injectable()
export class CurrentCalendarService extends DefaultApiService {
    getCurrentCalendar(): Observable<any> {
        const url = 'api/v1/current-academic-years';
        return this.getAll(url);
    }
}
