/**
 * Created by LifeSoft on 31/10/17.
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from './auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AddCategoryBody} from '../../models/util/add-category-body';

@Injectable()
export class EmployeeCategoryService {
    constructor(private http: HttpClient, private auth: AuthService) { }
    loadCategories(): Observable<any> {
        const headers = this.getDefaultHeaders();
        return this.http.get(environment.base_url + 'api/v1/categories', {headers: headers});
    }
    loadActiveCategories(): Observable<any> {
        const headers = this.getDefaultHeaders();
        return this.http.get(environment.base_url + 'api/v1/categories?active=1', {headers: headers});
    }
    addCategory(categoryBody: AddCategoryBody): Observable<any> {
        const headers = this.getDefaultHeaders();
        return this.http.post(environment.base_url + 'api/v1/categories/store', JSON.stringify(categoryBody), {headers: headers});
    }
    updateCategory(id: number, categoryBody: AddCategoryBody): Observable<any> {
        const headers = this.getDefaultHeaders();
        return this.http.put(environment.base_url + 'api/v1/categories/update/' + id, JSON.stringify(categoryBody), {headers: headers});
    }
    getDefaultHeaders(): HttpHeaders {
        const userToken = this.auth.getLoginUser().key;
        const headerJson = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': userToken
        };
        return new HttpHeaders(headerJson);
    }
}
