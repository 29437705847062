import {Injectable} from '@angular/core';
import {Role} from '../../models/role';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {DefaultHeaderService} from './util/default-header.service';
import {Observable} from 'rxjs';
import {RoleBody} from '../../models/util/role-body';
/**
 * Created by LifeSoft on 30/10/17.
 */
@Injectable()
export  class RoleManagerService {
    base_url = environment.base_url;
    constructor(private http: HttpClient,
                private header: DefaultHeaderService) { }
    saveRoles(roles: Role[]): void {
        localStorage.setItem('roles', JSON.stringify(roles));
    }
    authHasRole($roleName): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        for (const role of user.roles) {
            if ($roleName === role.name) {
                return true;
            }
        }
        return false;
    }
    authHasRoles(roles: Array<string>): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        const result = user.roles.filter((_role) => roles.includes(_role.name));
        return result.length > 0;
    }
    hasRole(role: Role, roles) {
        if (role === null || roles.length === 0) {
            return false;
        }
        for (const $role of roles) {
            if ($role === role.id) {
                return true;
            }
        }
        return false;
    }
    getActiveRoles(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/roles?active=1', {headers: this.header.getDefaultAuthHeaders()});
    }
    attachRoles(body: RoleBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/users/attach-roles', JSON.stringify(body),
                            {headers: this.header.getDefaultAuthHeaders()});
    }
}
