/**
 * Created by LifeSoft on 29/10/17.
 */
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
@Injectable()
export class RedirectService {
    constructor(private router: Router) { }
    onLoginRedirect($type: string, url?: string) {
        switch ($type) {
            case 'admin': {
                url  = url ? url : '/employees/dashboard';
                this.router.navigate([url]);
                break;
            }
            case 'employee': {
                this.router.navigate(['/employees/dashboard']);
                break;
            }
            case 'student': {
                this.router.navigate(['/students/dashboard']);
                break;
            }
            case 'guardian': {
                this.router.navigate(['/guardians/dashboard']);
                break;
            }
          case 'teacher': {
            this.router.navigate(['/teachers/dashboard']);
            break;
          }
            default: {
                this.router.navigate(['/']);
                break;
            }
        }
    }
}
