import { Component, OnInit } from '@angular/core';
// import { Title } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
// import { fadeInOutTranslate } from 'src/app/shared/elements/animation';
// import {Guardian} from '../../../../models/guardian';
// import { fadeInOutTranslate } from '../../../../../shared/elements/animation';
import { User } from '../../../models/user';
import { AuthService } from '../../../shared/services/auth.service';


@Component({
	selector: 'app-student-attendance',
	templateUrl: './student-attendance.component.html',
	styleUrls: ['./student-attendance.component.css'],
	// animations: [fadeInOutTranslate]
})
export class StudentAttendanceComponent implements OnInit {
	title = 'Student Attendance';
	safeSrc: SafeResourceUrl;
	private url = `${environment.main_url}react/index.html?page=${window.location.pathname}`
	loginUser: User;
	// best.geitt.com/react' + window.location.pathname

	constructor(
		private _title: Title,
		private sanitizer: DomSanitizer,
		private _auth: AuthService
	) {
		this.loginUser = this._auth.getLoginUser();
		this.url += `&token=${this.loginUser.key}`
		this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
	}

	ngOnInit() {
		// this.loginUser = this._auth.getLoginUser();
		// this._title.setTitle(this.title);
	}


}
