/**
 * Created by LifeSoft on 09/01/18.
 */
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {MicService} from '../../../models/util/mic.service';
import {User} from '../../../models/user';
import {AuthService} from '../../../shared/services/auth.service';
import {SubjectService} from '../../../shared/services/subject.service';
import {StudentService} from '../../../shared/services/student.service';
import {DefaultApiService} from '../../../shared/services/default-api.service';
import {Student, StudentResponse} from '../../../models/student';
import {ErrorService} from '../../../shared/services/util/error.service';
import {Subscription} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html'
})
export class StudentProfileComponent implements OnInit {
  title = 'Student Profile';
  cardSubTitle = '';
  loginStudent: User;
  profileBusy = false;
  student: Student;
  constructor(private _title: Title,
              private micService: MicService,
              private _auth: AuthService,
              private defaultApi: DefaultApiService,
              private errorService: ErrorService,
              private notificationService: NotificationsService) { }
  ngOnInit(): void {
    this._title.setTitle(this.title);
    this.onPageStart();
  }
  onPageStart(): void {
    this.loginStudent = this._auth.getLoginUser();
    this.getStudentByUserId();
  }
  onPageRefresh(refresh): void {
    if (refresh) {
      this.onPageStart();
    }
  }
  getFullName(model: any): string {
    return this.micService.getFullname(model);
  }
  getFullNameDefault(model: any): string {
    return this.micService.getFullNameDefault(model)
  }
  getStudentByUserId(): void {
    const url = 'api/v1/students/get-by-user-id/' + this.loginStudent.id;
    this.profileBusy = true;
    this.defaultApi.getById(url)
      .subscribe((response: StudentResponse) => {
          this.profileBusy = false;
          if (response.status) {
            this.student = response.data.student;
            this.cardSubTitle = this.getFullName(this.student);
          } else {
            this.notificationService.warn(this.title, response.message);
          }
        },
        (error) => {
          this.profileBusy = false;
          this.errorService.handleError(error);
        });
  }
}
