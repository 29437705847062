/**
 * Created by LifeSoft on 16/10/18.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {EmployeeService} from '../employee.service';
@Injectable()
export class EmployeeResolverService implements Resolve<any> {

  constructor(private employeeService: EmployeeService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.employeeService.getEmployeesWithDepartments();
  }

}
